import React, {Component} from "react"
import styles from "./Footer.module.css"
import {Model} from "Model"

interface Props {
    model: Model
}

export class Footer extends Component<Props, {}> {
    public render() {
        const model = this.props.model
        const currentCampus = model.document.json_data.campus
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>
                        <strong>The Salon Professional Academy</strong>
                    </p>
                    {!currentCampus ? (
                        <div>
                            <p className={styles.text}>
                                THE PLACE WHERE YOU BECOME WHO YOU WERE MEANT TO BE
                            </p>
                            <p className={styles.text}>Let us help you explore your options.</p>
                        </div>
                    ) : (
                        <div>
                            <p className={styles.text}>
                                {model?.campusInformationFooter?.name} |{" "}
                                {model?.campusInformationFooter?.address},{" "}
                                {model?.campusInformationFooter?.state} |{" "}
                                {model?.campusInformationFooter?.phone}
                            </p>
                        </div>
                    )}
                </div>
            </footer>
        )
    }
}
