const refreshSignaturesOrInitials = (json_data, form_ids, ids) => {
    // console.log("@@@@@@@@@@@ refreshSignaturesOrInitials Pdf:", {
    //     form_ids,
    //     ids
    // })

    if (json_data.forms) {
        for (let j = 0; j < form_ids.length; j++) {
            const form = form_ids[j]
            delete json_data.forms[form]
        }
    }
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i]
        delete json_data[id]
        delete json_data[`${id}_date`]
    }
}
const REFILL_FORMS_IDS = {
    all: {
        forms: [
            "enrollment_application",
            "pre_enrollment_info",
            "request_accommodations",
            "safety_security_questionnaire",
            "student_information",
            "text_kit_contract",
            "i_understand_form",
            "electronic_learning_policy",
            "photo_video_release_form",
            "electronic_signature_policy",
            "medical_marijuana",
            "drug_review_receipt",
            "policy_truth_candor",
            "biometric_privacy_policy",
            "student_handbook",
            "annual_notice",
            "authorization_research",
            "ipeds",
            "occupational_qualifications",
            "security_disclosure_report",
            "distance_education_policy"
        ],
        ids: [
            "student_signature_ea",
            "student_signature_pei",
            "student_signature_req",
            "student_signature_with_no_condition",
            "student_signature_ssq",
            "signature",
            "student_signature_tkc",
            "student_signature_iu",
            "student_signature_elp",
            "student_signature_pv",
            "guardian_signature_pv",
            "student_signature_esp",
            "student_signature_mm",
            "student_signature_drr",
            "student_signature_ptc",
            "student_signature_bp",
            "guardian_signature_bp",
            "student_signature_sh",
            "student_signature_an",
            "student_signature_ar",
            "guardian_signature_ar",
            "student_signature_ipeds",
            "student_signature_oq",
            "student_signature_sdr",
            "student_signature_education_policy"
        ]
    },
    enrollment_application: {
        forms: ["enrollment_application"],
        ids: ["student_signature_ea"]
    },
    pre_enrollment_info: {
        forms: ["pre_enrollment_info"],
        ids: ["student_signature_pei"]
    },
    request_accommodations: {
        forms: ["request_accommodations"],
        ids: ["student_signature_req", "student_signature_with_no_condition"]
    },
    safety_security_questionnaire: {
        forms: ["safety_security_questionnaire"],
        ids: ["student_signature_ssq"]
    },
    student_information: {
        forms: ["student_information"],
        ids: ["signature"]
    },
    text_kit_contract: {
        forms: ["text_kit_contract"],
        ids: ["student_signature_tkc"]
    },
    i_understand_form: {
        forms: ["i_understand_form"],
        ids: ["student_signature_iu"]
    },
    electronic_learning_policy: {
        forms: ["electronic_learning_policy"],
        ids: ["student_signature_elp"]
    },
    photo_video_release_form: {
        forms: ["photo_video_release_form"],
        ids: ["student_signature_pv", "guardian_signature_pv"]
    },
    electronic_signature_policy: {
        forms: ["electronic_signature_policy"],
        ids: ["student_signature_esp"]
    },
    medical_marijuana: {
        forms: ["medical_marijuana"],
        ids: ["student_signature_mm"]
    },
    drug_review_receipt: {
        forms: ["drug_review_receipt"],
        ids: ["student_signature_drr"]
    },
    policy_truth_candor: {
        forms: ["policy_truth_candor"],
        ids: ["student_signature_ptc"]
    },
    biometric_privacy_policy: {
        forms: ["biometric_privacy_policy"],
        ids: ["student_signature_bp", "guardian_signature_bp"]
    },
    student_handbook: {
        forms: ["student_handbook"],
        ids: ["student_signature_sh"]
    },
    annual_notice: {
        forms: ["annual_notice"],
        ids: ["student_signature_an"]
    },
    authorization_research: {
        forms: ["authorization_research"],
        ids: ["student_signature_ar", "guardian_signature_ar"]
    },
    ipeds: {
        forms: ["ipeds"],
        ids: ["student_signature_ipeds"]
    },
    occupational_qualifications: {
        forms: ["occupational_qualifications"],
        ids: ["student_signature_oq"]
    },
    security_disclosure_report: {
        forms: ["security_disclosure_report"],
        ids: ["student_signature_sdr"]
    },
    distance_education_policy: {
        forms: ["distance_education_policy"],
        ids: ["student_signature_education_policy"]
    }
}

const PDF_VIEWERS = {
    pdfHandbook: {
        id: 1,
        title: "Student Handbook",
        url: "https://admissions-tspanampa-documents.s3.amazonaws.com/HandbookJanuary2022.pdf"
    },
    pdfCatalog: {
        id: 2,
        title: "Student Catalog 2022",
        url: "https://admissions-tspanampa-documents.s3.amazonaws.com/CatalogJanuary2022.pdf"
    },
    pdfConsumerInformation: {
        id: 3,
        title: "Consumer Information 2022",
        url: "https://admissions-tspanampa-documents.s3.amazonaws.com/ConsumerInformation2022.pdf"
    }
}

export {refreshSignaturesOrInitials, REFILL_FORMS_IDS, PDF_VIEWERS}
