import React, {Component} from "react"
import styles from "./NavigationButton.module.css"
import {Model} from "Model"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import classNames from "classnames"
import {ReactComponent as Icon} from "./parts/img/icon.svg"
import {computed, observable, toJS} from "mobx"
import {
    isEnableSubmitButton,
    isEnableSubmitButtonAltoona,
    APPLICATION_TO_ENROLL,
    APPLICATION_PRIOR_TO_ENROLL,
    UPLOAD_DOCS_IN_PRIOR_ENROLL,
    UPLOAD_DOCS_AND_CONTINUE,
    hasAllFormsInSectionPriorToEnroll,
    ENROLLMENT_APPLICATION
} from "helper/Methods"
import Swal from "sweetalert2"

interface Props {
    model: Model
    className?: string
    setTabForms?: Function
}

@observer
export class NavigationButton extends Component<Props, {}> {
    @computed
    private get nextButtonEnabled(): boolean {
        const model = this.props.model
        if (model.getNextFormId() === null && model.getFormId() === "submission") {
            return false
        }
        return true
    }

    @observable
    private submitButtonEnabled = true

    @computed
    private get saveButtonEnabled(): boolean {
        const model = this.props.model
        if (model.form.type === "pdf" && !model.iframeLoaded) {
            return false
        }
        return true
    }

    public render() {
        const {model, className} = this.props
        const {
            document: {json_data}
        } = model
        return (
            <div className={classNames(styles.root, className)}>
                <button
                    className={`${styles.button} ${styles.marginRight}`}
                    disabled={model.getPreviousFormId() === null}
                    onClick={() => {
                        const form_id: FormID = model.getPreviousFormId()
                        model.setFormId(form_id)
                    }}>
                    <Icon className={styles.icon} width="12" height="12" />
                    BACK
                </button>

                {this.nextButtonEnabled && (
                    <>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "signature"
                                }
                            }}>
                            My Signature
                        </button>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "initials"
                                }
                            }}>
                            My Initials
                        </button>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "parent"
                                }
                            }}>
                            Parent Signature
                        </button>
                        <button
                            className={classNames(styles.button, styles.buttonSave)}
                            onClick={async () => {
                                const success = await model.save()
                                if (!success) {
                                    // alert("Please check errors")
                                }
                                this.props.setTabForms()
                            }}>
                            SAVE
                        </button>
                        {model.getFormId() === ENROLLMENT_APPLICATION ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    const success = await model.save()
                                    if (success) {
                                        const form_id: FormID = model.getNextFormId()
                                        model.setFormId(form_id)
                                    } else {
                                        // alert("Please check errors")
                                    }
                                }}>
                                SAVE &{" "}
                                {!json_data.isEnrollmentApplicationDocReady ? `SUBMIT` : `CONTINUE`}{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : model.getFormId() === APPLICATION_PRIOR_TO_ENROLL ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    console.log("saving and logout")
                                    const success = await model.savePriorAndContinue()
                                    if (success) {
                                        if (
                                            this.props.model.document.json_data.hasPriorCampusCode
                                        ) {
                                            const form_id: FormID = model.getNextFormId()
                                            model.setFormId(form_id)
                                        }
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "There are some errors.",
                                            text: "Please take a look."
                                        })
                                        // alert("Please check errors")
                                    }
                                }}>
                                SAVE &{" "}
                                {!json_data.hasPriorCampusCode &&
                                json_data.isEnrollmentApplicationDocReady
                                    ? `SUBMIT`
                                    : `CONTINUE`}{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : model.getFormId() === UPLOAD_DOCS_IN_PRIOR_ENROLL ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    if (
                                        !hasAllFormsInSectionPriorToEnroll(json_data.forms) &&
                                        !json_data.isPriorToEnrollSendEmail
                                    ) {
                                        // const priorToEnrollMessage = hasAllFormsInSectionPriorToEnroll(
                                        //     json_data.forms
                                        // )
                                        console.log("toJS", toJS(json_data.forms))

                                        Swal.fire({
                                            icon: "error",
                                            title: "You need to fill the previous forms first",
                                            text: "Please take a look."
                                        })
                                        // alert(
                                        //     `You need to fill the previous forms first. ${priorToEnrollMessage}`
                                        // )
                                    } else {
                                        const success = await model.saveAndContinueWithReadyToEnroll()
                                        if (success) {
                                            if (
                                                this.props.model.document.json_data
                                                    .isPriorToEnrollSendEmail
                                            ) {
                                                const form_id: FormID = model.getNextFormId()
                                                if (form_id) {
                                                    model.setFormId(form_id)
                                                }
                                            }
                                        } else {
                                            Swal.fire({
                                                icon: "error",
                                                title: "There are some errors.",
                                                text: "Please take a look."
                                            })
                                            // alert("Please check errors")
                                        }
                                    }
                                }}>
                                SAVE & {!json_data.isPriorToEnrollSendEmail ? `SUBMIT` : `CONTINUE`}{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : model.getFormId() === APPLICATION_TO_ENROLL ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    console.log("saving and logout")
                                    const success = await model.saveAndContinue()
                                    if (success) {
                                        if (this.props.model.document.json_data.hasCampusCode) {
                                            const form_id: FormID = model.getNextFormId()
                                            model.setFormId(form_id)
                                        }
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "There are some errors.",
                                            text: "Please take a look."
                                        })
                                        // alert("Please check errors")
                                    }
                                }}>
                                SAVE &{" "}
                                {json_data.isPriorToEnrollSendEmail && !json_data.hasCampusCode
                                    ? `SUBMIT`
                                    : `CONTINUE`}{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : model.getFormId() === UPLOAD_DOCS_AND_CONTINUE ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    const success = await model.save()
                                    if (success) {
                                        const form_id: FormID = model.getNextFormId()
                                        model.setFormId(form_id)
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "You have to upload a document to continue",
                                            text: "Please upload High School Diploma / GED."
                                        })
                                    }
                                }}>
                                SAVE & CONTINUE{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    const success = await model.save()
                                    if (success) {
                                        const form_id: FormID = model.getNextFormId()
                                        model.setFormId(form_id)
                                    } else {
                                        // alert("Please check errors")
                                    }
                                }}>
                                SAVE & CONTINUE{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        )}
                    </>
                )}

                {!this.nextButtonEnabled && (
                    <button
                        className={classNames(styles.button, styles.buttonNext)}
                        disabled={
                            json_data && json_data.isSubmitted
                                ? true
                                : json_data.campus === "Altoona"
                                ? isEnableSubmitButtonAltoona(json_data.forms || [])
                                : isEnableSubmitButton(json_data.forms || [])
                        }
                        onClick={async () => {
                            this.submitButtonEnabled = false
                            await model.submit()
                            // if (!success) {
                            //     alert("Can not submit")
                            //     return
                            // }
                            this.forceUpdate()
                        }}>
                        SUBMIT <Icon className={styles.icon} width="12" height="12" />
                    </button>
                )}
            </div>
        )
    }
}
