import {Model} from "./Model"

export class ModelListener {
    private cache = {}

    constructor(private model: Model) {
        this.onModel = this.onModel.bind(this)

        model.dispatcher2.addListener(this.onModel)
        this.onModel()
    }

    private onModel() {
        const json_data = this.model.document.json_data

        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)
        const enableTab = this.model.enableTabs.bind(this.model)
        const disableTab = this.model.disableTabs.bind(this.model)

        if (!json_data.hasPriorCampusCode && !json_data.isEnrollmentApplicationDocReady) {
            disableTab("prior_to_enroll")
            disableTab("ready_to_enroll")
            disableTab("enrollment_docs")
            disableTab("disclosures")
            disableTab("additional_docs")
            disableTab("submission")
            disable("application_prior_to_enroll")
            disable("pre_enrollment_info")
            disable("request_accommodations")
            disable("safety_security_questionnaire")
            disable("pdf_catalog")
            disable("application_upload_docs")
            disable("application_ready_to_enroll")
            disable("student_information")
            disable("i_understand_form")
            disable("distance_education_policy")
            disable("electronic_learning_policy")
            disable("photo_video_release_form")
            disable("electronic_signature_policy")
            disable("medical_marijuana")
            disable("drug_review_receipt")
            disable("policy_truth_candor")
            disable("biometric_privacy_policy")
            disable("pdf_handbook")
            disable("pdf_consumer_info")
            disable("annual_notice")
            disable("student_handbook")
            disable("ipeds")
            disable("security_disclosure_report")
            disable("occupational_qualifications")
            disable("text_kit_contract")
            disable("authorization_research")
        }

        if (json_data.isEnrollmentApplicationDocReady) {
            enable("application_prior_to_enroll")
            enableTab("prior_to_enroll")
        }

        if (json_data.hasPriorCampusCode && json_data.isEnrollmentApplicationDocReady) {
            disable("application_prior_to_enroll")
            enable("pre_enrollment_info")
            enable("request_accommodations")
            enable("safety_security_questionnaire")
            enable("occupational_qualifications")
            enable("pdf_catalog")
        }

        if (json_data.isPriorToEnrollSendEmail && json_data.hasPriorCampusCode) {
            enable("application_ready_to_enroll")
            enableTab("ready_to_enroll")
        }

        if (!json_data.hasCampusCode) {
            disable("i_understand_form")
            disable("electronic_learning_policy")
            disable("photo_video_release_form")
            disable("electronic_signature_policy")
            disable("medical_marijuana")
            disable("drug_review_receipt")
            disable("policy_truth_candor")
            disable("biometric_privacy_policy")
            disable("annual_notice")
            disable("pdf_handbook")
            disable("pdf_consumer_info")
            disable("student_handbook")
            disable("ipeds")
            disable("security_disclosure_report")
            disable("text_kit_contract")
            disable("authorization_research")
            disable("student_information")
            disable("distance_education_policy")
            disable("submission")
        }
        if (
            json_data.hasCampusCode &&
            json_data.isEnrollmentApplicationDocReady &&
            json_data.isPriorToEnrollSendEmail
        ) {
            disable("application_ready_to_enroll")
            enable("i_understand_form")
            enable("electronic_learning_policy")
            enable("photo_video_release_form")
            enable("electronic_signature_policy")
            enable("medical_marijuana")
            enable("drug_review_receipt")
            enable("policy_truth_candor")
            enable("biometric_privacy_policy")
            enable("annual_notice")
            enable("pdf_handbook")
            enable("pdf_consumer_info")
            enable("student_handbook")
            enable("ipeds")
            enable("security_disclosure_report")
            enable("text_kit_contract")
            enable("authorization_research")
            enable("student_information")
            enable("application_upload_docs")
            enable("submission")

            enableTab("ready_to_enroll")
            enableTab("enrollment_docs")
            enableTab("disclosures")
            enableTab("additional_docs")
            enableTab("submission")

            if (json_data.campus === "Altoona") {
                disable("distance_education_policy")
            } else {
                enable("distance_education_policy")
            }
        }
        disable("identity_authentication")
        disable("online_education_disclosure")
        disable("cpa_eligibility")
        disable("technical_standards")
        disable("him_technical_standards")
        disable("transferability_of_credits")
        disable("him_enrollment_agreement")
        disable("recruitment")
        disable("covid_19")

        this.onCampusChange()
        this.showProgramForms()
        this.onStateChange()

        if (json_data.student_athlete === "basketball") {
            enable("recruitment")
        } else {
            disable("recruitment")
        }

        let him17Campuses = [
            "ANU - Youngstown, OH",
            "ANU - Charlottesville, VA",
            "ANU - Danville, VA",
            "ANU - Harrisonburg, VA",
            "ANU - Lynchburg, VA",
            "ANU - Roanoke Valley, VA",
            "ANU - Dayton Area, OH",
            "ANU - Online Division"
        ]

        if (
            json_data.program_name === "Health Information Management" &&
            him17Campuses.indexOf(json_data.campus) !== -1
        ) {
            enable("him_enrollment_agreement")
        } else {
            disable("him_enrollment_agreement")
        }

        // URGENT FIX
        // Please check relation with him17Campuses up
        if (json_data.program_name === "Health Information Management") {
            enable("him_enrollment_agreement")
        }

        if (json_data.program_name === "CDL Training") {
            // enable(15)
            // enable(5)
            // enable(6)
            // disable(4)
            // disable(2)
            // disable(12)
        } else {
            enable("felony_acknowledgement")
            enable("education_disclosure")
            enable("transcript_request")
        }

        if (
            json_data.program_name === "Pharmacy Technician" ||
            json_data.program_name === "Pharmacy Technician (Associate Program)"
        ) {
            enable("covid_19")
        }
    }

    private onCampusChange() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("identity_authentication")
        disable("online_education_disclosure")
        // disable(15)
        disable("covid_19")

        if (this.model.document.json_data.campus === "ANU - Distance Education Division") {
            enable("identity_authentication")
            enable("online_education_disclosure")
        }
    }

    private onStateChange() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("transferability_of_credits")
        disable("immunization_verification")

        if (this.model.document.json_data.state === "Tennessee") {
            enable("transferability_of_credits")
            enable("immunization_verification")
        }
    }

    private showProgramForms() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("cpa_eligibility")
        disable("technical_standards")
        disable("him_technical_standards")

        let program = this.model.document.json_data.program_name

        if (program == null) {
            return
        }

        if (this.validateProgramTS(program)) {
            enable("technical_standards")
        }

        if (this.validateProgramHIM(program)) {
            enable("him_technical_standards")
        }

        if (this.validateProgramCPA(program)) {
            enable("cpa_eligibility")
        }
    }

    private validateProgramTS(program: string) {
        let programsArray = [
            "Surgical Technology & Central Sterile Processing",
            "Medical Assisting Associate",
            "Phlebotomy and ECG Technician",
            "Medical Assisting (Certificate Program)"
        ]

        if (programsArray.indexOf(program) !== -1) {
            return true
        }

        return false
    }

    private validateProgramHIM(program) {
        if (program === "Health Information Management") {
            return true
        }
        return false
    }

    private validateProgramCPA(program) {
        let programsArray = [
            "Business Administration - Accounting (Bachelor Program)",
            "Business Administration - Accounting (Associate Program)",
            "Accounting (Diploma Program)"
        ]

        if (programsArray.indexOf(program) !== -1) {
            return true
        }

        return false
    }
}
