import React, {Component} from "react"
import styles from "./PdfViewer.module.css"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"

interface Props {
    model: Model
    id: number
    title: string
    url: string
}

const rules = {}

@observer
export class PdfViewer extends Component<Props> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    private iframe: HTMLIFrameElement

    constructor(props) {
        super(props)

        this.state = {numPages: null, pageNumber: 1}

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate PdfViewer")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private onChange(id: string, value) {
        this.json_data[id] = value

        this.forceUpdate()
    }

    public render() {
        const {title, url, id} = this.props

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>{title}</h2>
                {/* #toolbar=0 no toolbar in embed*/}
                <iframe
                    id={`admissions-pdf-view-${id}`}
                    title="PDF Viewer"
                    src={url}
                    frameBorder={0}
                    width="100%"
                    height="100%"
                    className={styles.iframe}
                    allowFullScreen
                    ref={(f) => {
                        this.iframe = f as HTMLIFrameElement
                    }}
                />
            </div>
        )
    }
}
