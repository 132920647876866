import React, {Component} from "react"
import {Model} from "../../Model"
import {CareerInformation} from "../../pages/career-information"
import {Main} from "../../pages/main"
import {observer} from "mobx-react"
import {UploadDocs} from "../../pages/upload-docs"
import {SubmissionForm} from "../../pages/submission-form"
import {ReadyToEnrollForm} from "../../pages/ready-to-enroll"
import {PriorToEnrollForm} from "../../pages/prior-to-enroll"
// import {PDFCatalog} from "../../pages/pdf-catalog"
// import {PDFConsumerInfo} from "pages/pdf-consumer-info"
// import {PDFHandbook} from "pages/pdf-handbook"
import {PdfViewer} from "pages/pdf-viewer"
import {PDF_VIEWERS} from "lib/functions/helper"

interface Props {
    model: Model
}

@observer
export class Form extends Component<Props, {}> {
    public render() {
        const model = this.props.model
        const {pdfCatalog, pdfConsumerInformation, pdfHandbook} = PDF_VIEWERS
        console.log("Form model:", model)

        let html = <Main model={model} /> // forms-pdf

        const form_id = model.form.form_id

        if (form_id === "application_career_information") {
            html = <CareerInformation model={model} />
        }

        if (form_id === "application_upload_docs") {
            html = <UploadDocs model={model} />
        }

        if (form_id === "application_ready_to_enroll") {
            html = <ReadyToEnrollForm model={model} />
        }

        if (form_id === "application_prior_to_enroll") {
            html = <PriorToEnrollForm model={model} />
        }

        if (form_id === "pdf_catalog") {
            html = (
                <PdfViewer
                    model={model}
                    title={pdfCatalog.title}
                    url={pdfCatalog.url}
                    id={pdfCatalog.id}
                />
            )
        }

        if (form_id === "pdf_consumer_info") {
            html = (
                <PdfViewer
                    model={model}
                    title={pdfConsumerInformation.title}
                    url={pdfConsumerInformation.url}
                    id={pdfConsumerInformation.id}
                />
            )
        }

        if (form_id === "pdf_handbook") {
            html = (
                <PdfViewer
                    model={model}
                    title={pdfHandbook.title}
                    url={pdfHandbook.url}
                    id={pdfHandbook.id}
                />
            )
        }

        if (form_id === "submission") {
            html = <SubmissionForm model={model} />
        }

        return html
    }
}
