import React, {Component} from "react"
import styles from "./CareerInformation.module.css"
import {Label} from "components/inputs/Label"
import {SelectOption} from "data/DataStore"
import {JsonDocument, Model, CareerDocument} from "../../Model"
import {Campus, Program, Advisor, CareerDate} from "data/interfaces"
import {observable} from "mobx"
import {Select} from "components/inputs/Select"
import {RadioButton} from "components/inputs/RadioButton"
import {Disclosure} from "components/Disclosure"
import {findErrors} from "../../lib/functions/findErrors"
import {observer} from "mobx-react"
import {formatStandarUSDate, WILDCARD_DC} from "helper/Methods"
import Aesthetically from "aesthetically"
import {REFILL_FORMS_IDS, refreshSignaturesOrInitials} from "lib/functions/helper"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    program: mandatory,
    starting_date: mandatory,
    campus: mandatory,
    advisor: mandatory,
    has18: mandatory
}

@observer
export class CareerInformation extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}
    private career_data: CareerDocument
    private career_campuses: any[] = []
    private career_programs: any[] = []
    private career_advisors: any[] = []
    private career_dates: any[] = []
    private CAREER_KEYS = [
        "campus",
        "program",
        "advisor",
        "year",
        "campuses",
        "course_load",
        "times"
    ]
    private EXTRA_INFO = "extra"
    private STARTING_DATE = "starting_date"
    private CURRENT_START_DATE = "start_date"
    private CURRENT_START_DATE_INFO = "startDateInfo"

    constructor(props) {
        super(props)
        console.log("Inside constructor CareerInformation")

        this.onChange = this.onChange.bind(this)
        this.onInitFillSelectOptions = this.onInitFillSelectOptions.bind(this)
        this.onModel = this.onModel.bind(this)
        this.json_data = props.model.document.json_data
        if (!this.json_data[this.EXTRA_INFO]) {
            this.initExtraInfo() // todo: careful with this.
        }
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    private findByKey(list, keyField) {
        return list.find(({code}) => code === keyField)
    }

    private findByStartDateKey(list, keyField) {
        const [startDate, index] = keyField.split("_")
        return list.find(({start}, i) => start === startDate && Number(index) === i)
    }

    private initExtraInfo() {
        const extra = this.EXTRA_INFO
        this.json_data[extra] = {}
    }

    private addExtraInfoCampusProgramYear() {
        const [campus, program, advisor, yearInfo, campusesInfo] = this.CAREER_KEYS
        const extra = this.EXTRA_INFO
        const fieldCampus = this.json_data[campus]
        const fieldProgram = this.json_data[program]
        const fieldAdvisor = this.json_data[advisor]
        const fieldStartDate = this.json_data[this.STARTING_DATE]
        if (fieldCampus && fieldProgram) {
            const {campuses, year} = this.career_data
            const foundCampus = this.findByKey(campuses, fieldCampus)
            if (foundCampus) {
                const {programs, advisors} = foundCampus
                const foundProgram = this.findByKey(programs, fieldProgram)
                const foundAdvisor = advisors.find(({email}) => email === fieldAdvisor)
                if (foundProgram) {
                    const {
                        career_dates: {dates, id}
                    } = foundProgram
                    const foundStartDate = this.findByStartDateKey(dates, fieldStartDate)
                    this.json_data[extra][campus] = foundCampus
                    this.json_data[extra][program] = foundProgram
                    this.json_data[extra][advisor] = foundAdvisor
                    this.json_data[extra][yearInfo] = year
                    this.json_data[extra][campusesInfo] = this.props.model.campusesInfo
                    this.json_data[extra][this.CURRENT_START_DATE_INFO] = {
                        id,
                        ...foundStartDate
                    }
                }
            }
        }
    }

    public onInitFillSelectOptions() {
        const [campus, program, advisor, , , ,] = this.CAREER_KEYS
        this.career_data = this.props.model.careerInfo
        if (this.career_data) {
            // console.log('this.career_data:', toJS( this.career_data));
            this.career_campuses = this.fillSelectOption(this.career_data.campuses)
            if (this.json_data[campus]) {
                const campusFound = this.career_data.campuses.find(
                    ({code}) => code === this.json_data[campus]
                )
                this.props.model.campusInformationFooter = campusFound
                // console.log('campus found:', toJS(campusFound), this.json_data["starting_date"]);

                if (campusFound) {
                    this.career_programs = this.fillSelectOption(campusFound.programs)
                    this.career_advisors = this.fillAdivisors(campusFound.advisors)
                    if (this.json_data[program]) {
                        const programFound = campusFound.programs.find(
                            ({code}) => code === this.json_data[program]
                        )
                        if (campusFound.code !== WILDCARD_DC) {
                            this.career_dates = this.fillCareerDates(
                                programFound.career_dates.dates
                            )
                        } else {
                            this.career_dates = this.fillCareerDates(
                                // this.fillCareerDatesDC(
                                programFound.career_dates.dates
                            )
                        }
                    } else {
                        this.career_dates = []
                    }
                    this.addExtraInfoCampusProgramYear()
                } else {
                    this.career_programs = []
                    this.career_advisors = []
                    this.career_dates = []
                    this.json_data[program] = ""
                    this.json_data[advisor] = ""
                    this.json_data[this.STARTING_DATE] = ""
                    this.json_data[this.CURRENT_START_DATE] = ""
                }
            } else {
                this.career_programs = []
                this.career_advisors = []
                this.career_dates = []
                this.json_data[program] = ""
                this.json_data[advisor] = ""
                this.json_data[this.STARTING_DATE] = ""
                this.json_data[this.CURRENT_START_DATE] = ""
            }
        }
    }

    public async componentDidUpdate() {
        this.onInitFillSelectOptions()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
        this.onInitFillSelectOptions()
        this.forceUpdate()
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        // console.log("Validate CareerInformation")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private fillCareerDatesDC(list): SelectOption[] {
        return list.map((el: CareerDate, i) => {
            const formattedDate = formatStandarUSDate(el.start)
            if (el.course_load && el.time) {
                return {
                    value: `${el.start}_${i}`,
                    label: `${formattedDate} - ${Aesthetically.format(el.time, "serf-italic")}`,
                    course_load: el.course_load,
                    times: el.time
                }
            } else if (el.course_load) {
                return {
                    value: `${el.start}_${i}`,
                    label: `${formattedDate}`,
                    course_load: el.course_load
                }
            }
            return {value: `${el.start}_${i}`, label: `${formattedDate}`}
        })
    }

    private fillCareerDates(list): SelectOption[] {
        return list.map((el: CareerDate, i) => {
            const formattedDate = formatStandarUSDate(el.start)
            // if (el.course_load && el.time) {
            //     return {
            //         value: `${el.start}_${i}`,
            //         label: `${formattedDate} -  ${Aesthetically.format(
            //             el.course_load,
            //             "sans-serf"
            //         )} -  ${Aesthetically.format(el.time, "serf-italic")}`,
            //         course_load: el.course_load,
            //         times: el.time
            //     }
            // } else if (el.course_load) {
            //     return {
            //         value: `${el.start}_${i}`,
            //         label: `${formattedDate} - ${Aesthetically.format(
            //             el.course_load,
            //             "sans-serf"
            //         )}`,
            //         course_load: el.course_load
            //     }
            // }
            return {value: `${el.start}_${i}`, label: `${formattedDate}`}
        })
    }

    private fillSelectOption(list): SelectOption[] {
        return list.map((el: Campus | Program) => {
            return {id: el.id, value: el.code, label: el.name}
        })
    }

    private fillAdivisors(list): SelectOption[] {
        return list.map((el: Advisor) => {
            return {id: el.id, value: el.email, label: `${el.name} (${el.email})`}
        })
    }

    private fillSchedules(list): SelectOption[] {
        return list.map((el: any) => {
            return {id: el.id, value: el.type, label: el.name}
        })
    }

    private onChange(id: string, value, times_value?: string, course_load_value?: string) {
        // console.log("id value", {id, value, times_value, course_load_value})

        if (this.json_data.isSubmitted) {
            delete this.json_data.isSubmitted
        }
        this.json_data[id] = value
        const [campus, program, advisor, , , courseLoadKey, timesKey] = this.CAREER_KEYS
        if (id === this.STARTING_DATE) {
            const [onlyDate] = value.split("_")
            this.json_data[this.CURRENT_START_DATE] = onlyDate
            this.json_data[courseLoadKey] = course_load_value
            this.json_data[timesKey] = times_value
        }
        if (id === campus) {
            this.json_data[program] = ""
            this.json_data[advisor] = ""
            this.json_data[this.STARTING_DATE] = ""
            this.json_data[this.CURRENT_START_DATE] = ""
            this.json_data[courseLoadKey] = ""
            this.json_data[timesKey] = ""
            this.initExtraInfo()
        }
        if (id === program) {
            this.json_data[this.STARTING_DATE] = ""
            this.json_data[this.CURRENT_START_DATE] = ""
            this.json_data[courseLoadKey] = ""
            this.json_data[timesKey] = ""
        }
        if (id === "program_name" || id === "campus") {
            this.removeIdsFromDocument(this.json_data)
        }
        this.onInitFillSelectOptions()
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [...REFILL_FORMS_IDS.all.forms],
            [...REFILL_FORMS_IDS.all.ids]
        )
    }

    public render() {
        const model = this.props.model
        const {career_campuses, career_programs, career_advisors, career_dates, json_data} = this
        const p = {model, onChange: this.onChange, errors: this.errors}
        const r = {onChange: this.onChange, json_data: this.json_data}

        return (
            <React.Fragment>
                {json_data.role ? !json_data.accepted && <Disclosure model={model} /> : null}
                <div className={styles.root}>
                    <h2 className={styles.h2}>CAREER INFORMATION</h2>
                    <div className={styles.form}>
                        <Label text="Campus" required>
                            <Select id="campus" options={career_campuses} {...p} />
                        </Label>

                        <Label text="Program" required>
                            <Select id="program" options={career_programs} {...p} />
                        </Label>

                        <Label text="Advisor" required>
                            <Select id="advisor" options={career_advisors} {...p} />
                        </Label>
                        <Label text="Starting date" required>
                            <Select id="starting_date" options={career_dates} {...p} />
                        </Label>
                        <div className={styles.addendumForm}>
                            <Label text="Are you 18 or older?" required>
                                <div className={styles.ageOf18}>
                                    <RadioButton
                                        label="Yes"
                                        value="yes"
                                        group_name="has18"
                                        {...r}
                                    />
                                    <RadioButton label="No" value="no" group_name="has18" {...r} />
                                </div>
                                {this.errors["has18"] && (
                                    <p className={styles.error}>{this.errors["has18"]} </p>
                                )}
                            </Label>
                        </div>
                    </div>
                    <p className={styles.text}>
                        <span>*</span> Indicates that a response is required
                    </p>
                </div>
            </React.Fragment>
        )
    }
}
