import moment from "moment"
import {FormID} from "../interfaces"

const APPLICATION_TO_ENROLL = "application_ready_to_enroll"

const APPLICATION_PRIOR_TO_ENROLL = "application_prior_to_enroll"

const UPLOAD_DOCS_IN_PRIOR_ENROLL: FormID = "pdf_catalog"

const UPLOAD_DOCS_AND_CONTINUE: FormID = "application_upload_docs"

const formatDateMonthDayYear = (date) => moment(date).format(" MMMM D [,] YYYY")

const formatStandarUSDate = (date) => moment(date).format(" MM/DD/YYYY")

const REFILL_FORMS: FormID[] = ["enrollment_agreement", "opt_outs"]

const ENROLLMENT_APPLICATION = "enrollment_application"

const FORM_ENROLLMENT_APPLICATION_SIZE = 2

const PRIOR_TO_ENROLL_FILLED_SECTION = [
    "pre_enrollment_info",
    "request_accommodations",
    "safety_security_questionnaire",
    "occupational_qualifications"
]

const ALL_FORMS_IN_TABS = [
    "application_career_information",
    "student_information",
    "application_upload_docs",
    "i_understand_form",
    "distance_education_policy",
    "electronic_learning_policy",
    "photo_video_release_form",
    "electronic_signature_policy",
    "medical_marijuana",
    "drug_review_receipt",
    "policy_truth_candor",
    "biometric_privacy_policy",
    "pdf_catalog",
    "pdf_consumer_info",
    "pdf_handbook",
    "annual_notice",
    "student_handbook",
    "enrollment_application",
    "ipeds",
    "security_disclosure_report",
    "occupational_qualifications",
    "safety_security_questionnaire",
    "request_accommodations",
    "text_kit_contract",
    "authorization_research",
    "pre_enrollment_info",
    "application_ready_to_enroll",
    "application_prior_to_enroll",
    "submission"
]

const ALL_FORMS = [
    "enrollment_application",
    "pre_enrollment_info",
    "request_accommodations",
    "safety_security_questionnaire",
    "occupational_qualifications",
    "pdf_catalog",
    "student_information",
    "text_kit_contract",
    "i_understand_form",
    "distance_education_policy",
    "electronic_learning_policy",
    "photo_video_release_form",
    "electronic_signature_policy",
    "medical_marijuana",
    "drug_review_receipt",
    "policy_truth_candor",
    "biometric_privacy_policy",
    "annual_notice",
    "student_handbook",
    "ipeds",
    "security_disclosure_report",
    "authorization_research",
    "pdf_consumer_info",
    "pdf_handbook",
    "application_upload_docs",
]

const ALL_FORMS_ALTOONA = [
    "enrollment_application",
    "pre_enrollment_info",
    "request_accommodations",
    "safety_security_questionnaire",
    "occupational_qualifications",
    "pdf_catalog",
    "student_information",
    "text_kit_contract",
    "i_understand_form",
    "electronic_learning_policy",
    "photo_video_release_form",
    "electronic_signature_policy",
    "medical_marijuana",
    "drug_review_receipt",
    "policy_truth_candor",
    "biometric_privacy_policy",
    "annual_notice",
    "student_handbook",
    "ipeds",
    "security_disclosure_report",
    "authorization_research",
    "pdf_consumer_info",
    "pdf_handbook",
    "application_upload_docs",
]

const removeJsonDataForms = (currentJsonDataForms) => {
    return Object.keys(currentJsonDataForms)
        .filter((key) => !REFILL_FORMS.includes(key as FormID))
        .reduce((obj, key) => {
            obj[key] = currentJsonDataForms[key]
            return obj
        }, {})
}

const hasAllFormsInSectionPriorToEnroll = (currentJsonDataForms) => {
    const filledForms = PRIOR_TO_ENROLL_FILLED_SECTION.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (PRIOR_TO_ENROLL_FILLED_SECTION.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? true : false
}

const isEnableSubmitButton = (currentJsonDataForms) => {
    const filledForms = ALL_FORMS.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (ALL_FORMS.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

const isEnableSubmitButtonAltoona = (currentJsonDataForms) => {
    const filledForms = ALL_FORMS_ALTOONA.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (ALL_FORMS_ALTOONA.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

const currentSizeForm = (currentJsonDataForms, constantForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (constantForms.includes(elementKey as FormID)) counter += 1
    }
    return counter
}

const [PRIOR_CAMPUS_CODE, READY_CAMPUS_CODE] = ["prior_campus_code", "ready_campus_code"]

const WILDCARD_DC = "Washington, DC"

export {
    formatDateMonthDayYear,
    formatStandarUSDate,
    removeJsonDataForms,
    isEnableSubmitButton,
    isEnableSubmitButtonAltoona,
    currentSizeForm,
    ENROLLMENT_APPLICATION,
    FORM_ENROLLMENT_APPLICATION_SIZE,
    APPLICATION_TO_ENROLL,
    APPLICATION_PRIOR_TO_ENROLL,
    ALL_FORMS_IN_TABS,
    ALL_FORMS,
    PRIOR_CAMPUS_CODE,
    READY_CAMPUS_CODE,
    hasAllFormsInSectionPriorToEnroll,
    UPLOAD_DOCS_IN_PRIOR_ENROLL,
    UPLOAD_DOCS_AND_CONTINUE,
    WILDCARD_DC
}
