import React, {Component} from "react"
import styles from "./PriorToEnrollForm.module.css"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {PRIOR_CAMPUS_CODE} from "helper/Methods"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    prior_campus_code: mandatory
}

@observer
export class PriorToEnrollForm extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
        // this.forceUpdate()
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate prior to enroll")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.forceUpdate()
    }

    public render() {
        const {model} = this.props
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}

        if (model.document.json_data && model.document.json_data.isSubmitted) {
            // message = "Congratulations on taking the next step in advancing your career!"
        }

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>Prior to enroll</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>
                    <strong>Please enter your campus code to continue.</strong>
                </h3>
                <div className={styles.form}>
                    <Label text="Campus code" required>
                        <Input id="prior_campus_code" {...p} placeholder={`Insert campus code`} />
                    </Label>
                    <button
                        className={styles.btnCheck}
                        // disabled={model.document.json_data.isValidPriorCampusCode}
                        onClick={async () => {
                            if (this.isValid()) {
                                await model.checkPriorCampusCode({type: PRIOR_CAMPUS_CODE})
                            }
                        }}>
                        Check
                    </button>
                </div>
                <div className={styles.text}>
                    <p>
                        <span>*</span> If you do not have a campus code,{" "}
                        <strong>an advisor will contact you shortly</strong>. Providing you with the
                        required campus code.
                    </p>
                </div>
                {/* <div className={styles.card}>
                    <h2>Testing purposes, this section will be removed</h2>
                    <i>Please use any of those codes below.</i>
                    <ul className={styles.list}>
                        {[
                            "TSPA_ALTOONA_PRIOR_CAMPUS_CODE_123",
                            "TSPA_DC_PRIOR_CAMPUS_CODE_123",
                            "TSPA_FARGO_PRIOR_CAMPUS_CODE_123",
                            "TSPA_FT_MYERS_PRIOR_CAMPUS_CODE_123"
                        ].map((text, i) => (
                            <li key={i}>
                                <strong>{text}</strong>
                            </li>
                        ))}
                    </ul>
                </div> */}
            </div>
        )
    }
}
