import React, {Component} from "react"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrorUploadDocs} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import styles from "./UploadDocs.module.css"
import {UploadInput} from "./upload-input"

interface Props {
    model: Model
}

const rules = {
    highSchoolDiploma: {
        presence: {allowEmpty: false, message: "^high school diploma can't be empty"}
    }
    // transcipt: {presence: {allowEmpty: false, message: "^transcipt can't be empty"}}
}

@observer
export class UploadDocs extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        const {isValid, errors} = findErrorUploadDocs(this.json_data.uploads, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        if (this.json_data.isSubmitted) {
            delete this.json_data.isSubmitted
        }
        this.json_data[id] = value
        this.forceUpdate()
    }

    public render() {
        const {model} = this.props
        const uploads = this.props.model.document.json_data.uploads || []
        const {errors} = this
        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>Upload Documents</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>
                    Upload supporting documents
                </h3>
                <p className={styles.working}>
                    <strong>To upload documents</strong>, you can scan them and save the files onto
                    our computer OR take a clear picture using your smartphone's camera.{" "}
                    <strong>Please attempt to save them under your complete name</strong>.
                </p>
                <div style={{marginTop: "60px"}}></div>
                {/* <span className={`${styles.red}`}>*</span> */}
                <UploadInput
                    model={model}
                    title={`* High School Diploma / GED`}
                    btnId={`highSchoolDiploma`}
                    uploads={uploads}
                />
                {errors && <p className={styles.error}>{errors["highSchoolDiploma"]} </p>}
                <UploadInput
                    model={model}
                    title={`High School Transcript`}
                    btnId={`transcipt`}
                    uploads={uploads}
                />
                {/* {errors && <p className={styles.error}>{errors["transcipt"]} </p>} */}
                <UploadInput
                    model={model}
                    title={`Driver License`}
                    btnId={`driver_license`}
                    uploads={uploads}
                />
                <UploadInput
                    model={model}
                    title={`Social Security`}
                    btnId={`social_security`}
                    uploads={uploads}
                />
                <UploadInput
                    model={model}
                    title={`Birth Certificate`}
                    btnId={`birth_certificate`}
                    uploads={uploads}
                />
                <p className={styles.text}>
                    <span>*</span> Indicates that the document is required
                </p>
            </div>
        )
    }
}
