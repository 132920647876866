import React, {Component} from "react"
import styles from "./Item.module.css"
import {observer} from "mobx-react"
import classNames from "classnames"
import {ReactComponent as CheckIcon} from "./parts/img/check-mark.svg"
import {ReactComponent as MinusIcon} from "./parts/img/minus-circle.svg"
import {ReactComponent as TriangleIcon} from "./parts/img/triangle.svg"
import {ReactComponent as CheckCircleIcon} from "./parts/img/check-circle.svg"
import {ReactComponent as DesktopMinusIcon} from "./parts/img/decktop-minus-circle.svg"
import {FormID, Screen} from "interfaces"
import {SubMenu} from "./parts/SubMenu"
import {computed, observable} from "mobx"
import {Model} from "Model"

interface Props {
    id: string
    tab_id: string
    title: string
    tabletTitle: string
    items: {value: FormID; label: string}[]
    onTitleClick: (tab_id: string) => void
    onClick: (id: FormID) => void
    selected: FormID
    checkedItems: FormID[]
    enabledItems: FormID[]
    isOpen: boolean
    disableTab?: boolean
    model: Model
}

@observer
export class Item extends Component<Props, {}> {
    @observable
    private screen: Screen = "mobile"

    private onChangeScreen() {
        this.screen = "mobile"
        if (window.innerWidth > 660) {
            this.screen = "tablet"
        }
        if (window.innerWidth > 1400) {
            this.screen = "desktop"
        }
    }

    @computed
    private get checked(): boolean {
        const {title, items, enabledItems, checkedItems} = this.props
        // console.log('@@@@@@@@@@@@@', {title, items, enabledItems, checkedItems});
        if (items.length === 0) {
            return false
        }
        const ids = items.map((i) => {
            return i.value
        })

        if (title === "Forms") {
            let enabledPdfArray = this.getEnabledPdf(ids, enabledItems)
            return this.smallArrayInBig(checkedItems, enabledPdfArray)
        } else {
            return this.smallArrayInBig(checkedItems, ids)
        }
    }

    private getEnabledPdf(a, b) {
        let sortedA = a.concat().sort()
        let sortedB = b.concat().sort()
        let enabledPdfItems = []
        let i = 0
        let j = 0

        while (i < a.length && j < b.length) {
            if (sortedA[i] === sortedB[j]) {
                enabledPdfItems.push(sortedA[i])
                i++
                j++
            } else if (sortedA[i] < sortedB[j]) {
                i++
            } else {
                j++
            }
        }
        return enabledPdfItems
    }

    private smallArrayInBig(small, big) {
        return big.every((ai) => small.includes(ai))
    }

    public render() {
        const {
            id,
            tab_id,
            title,
            tabletTitle,
            items,
            onClick,
            isOpen,
            selected,
            checkedItems,
            enabledItems,
            onTitleClick,
            disableTab,
            model
        } = this.props
        // console.log('checkedItems', items, checkedItems);

        let currentTitle: string
        if (this.screen === "tablet") {
            currentTitle = tabletTitle
        } else currentTitle = title

        return (
            <li className={styles.root}>
                <button
                    className={`${styles.button} ${model.checkByStages(
                        styles,
                        disableTab,
                        tab_id
                    )}`} //{classNames(styles.button, this.checked && styles.buttonChecked)}
                    onClick={() => {
                        onTitleClick(id)
                    }}
                    disabled={disableTab}>
                    <Icon checked={this.checked} screen={this.screen} />
                    <span>{currentTitle}</span>
                    <TriangleIcon
                        className={classNames(
                            styles.triangleIcon,
                            isOpen && styles.triangleIconRotate
                        )}
                        width="14"
                        height="8"
                    />
                </button>
                {isOpen && (
                    <SubMenu
                        items={items}
                        onClick={onClick}
                        selected={selected}
                        checkedItems={checkedItems}
                        enabledItems={enabledItems}
                        screen={this.screen}
                    />
                )}
            </li>
        )
    }

    componentDidMount() {
        window.addEventListener("resize", this.onChangeScreen.bind(this))
        this.onChangeScreen()
    }
}
interface IconProps {
    checked: boolean
    screen: Screen
}

function Icon({checked, screen}: IconProps) {
    const props = {width: "14", height: "14"}

    if (checked && screen === "mobile") {
        return <CheckCircleIcon {...props} className={styles.icon} />
    }

    if (checked && screen === "tablet") {
        return <CheckIcon {...props} className={styles.icon} />
    }

    if (checked && screen === "desktop") {
        return <CheckCircleIcon {...props} className={styles.icon} />
    }

    if (!checked && screen === "mobile")
        return <MinusIcon {...props} className={styles.iconMinus} />

    if (!checked && screen === "tablet")
        return <MinusIcon {...props} className={styles.iconMinus} />

    if (!checked && screen === "desktop") {
        return <DesktopMinusIcon {...props} className={styles.iconMinus} />
    }
    return null
}
